import { Collapse, Stack } from "@mui/material";
import { MaxYear, MeasureCalculationGranularity, MinYear } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import CalculationTimerangeSelect from "../../../components/input/date/CalculationTimerangeSelect";
import Select from "../../../components/input/select/Select";
import { translationKeys } from "../../../translations/main-translations";

export interface ITimeRange {
    start: Moment | null;
    end: Moment | null;
}

interface ITimeRangeOption {
    label: string;
    value: {
        start: Moment | null;
        end: Moment | null;
        customRange?: boolean;
    };
}

const withoutRangeValue = {
    start: null,
    end: null,
};

const isValidFiscalYear = (fiscalYear: ITimeRange) => fiscalYear?.start != null && fiscalYear.end != null;

const isSameFiscalYear = (fiscalYear: ITimeRange, otherFiscalYear: ITimeRange) => {
    if (fiscalYear.start === null || fiscalYear.end === null) {
        return fiscalYear.start === otherFiscalYear.start && fiscalYear.end === otherFiscalYear.end;
    }
    return (
        isValidFiscalYear(fiscalYear) &&
        isValidFiscalYear(otherFiscalYear) &&
        fiscalYear.start.isSame(otherFiscalYear.start) &&
        fiscalYear.end.isSame(otherFiscalYear.end)
    );
};

const getFiscalYearRanges = (firstMonthInFiscalYear: number, yearsBefore: number, yearsAfter: number): { start: Moment; end: Moment }[] => {
    const startOfCurrentFiscalYear = moment.utc().month(firstMonthInFiscalYear).startOf("month");

    if (moment.utc().isBefore(startOfCurrentFiscalYear)) {
        // start of fiscal year in this calendar year has not yet passed
        startOfCurrentFiscalYear.subtract(1, "years");
    }

    const firstFiscalYear = startOfCurrentFiscalYear.clone().subtract(yearsBefore, "years");
    const numberOfYears = yearsBefore + yearsAfter + 1;
    return [...Array(numberOfYears).keys()]
        .map((index) => firstFiscalYear.clone().add(index, "years"))
        .map((start) => ({
            start,
            end: start.clone().add(1, "years").subtract(1, "days"),
        }));
};

const formatFiscalYear = (startOfFiscalYear: Moment) => {
    const currentYear = startOfFiscalYear.format("Y");
    const nextYear = startOfFiscalYear.clone().add(1, "years").format("YY");
    const startsInJanuary = startOfFiscalYear.month() === 0;
    return startsInJanuary ? currentYear : `${currentYear}/${nextYear}`;
};

interface ITimeRangeProps {
    yearsBefore?: number;
    yearsAfter?: number;
    financialMonth?: number;
    value: ITimeRange;
    onChange?: (change: ITimeRange) => void;
    menuPortalTarget?: HTMLElement | null;
    translate: TFunction;
    disabled?: boolean;
    showOnlyFiscalYears?: boolean;
}

const TimeRangeSelect = ({
    yearsBefore = 2,
    yearsAfter = 2,
    financialMonth = 0,
    value,
    onChange,
    menuPortalTarget,
    translate,
    disabled,
    showOnlyFiscalYears,
}: ITimeRangeProps) => {
    const handleOptionChange = (option: ITimeRangeOption | null) => {
        onChange && option != null && onChange(option.value);
    };

    const handleStartDateChange = (change: Moment) => {
        onChange?.({
            start: change,
            end: value.end,
        });
    };

    const handleEndDateChange = (change: Moment) => {
        onChange?.({
            start: value.start,
            end: change,
        });
    };

    const fiscalYearOptions = getFiscalYearRanges(financialMonth, yearsBefore, yearsAfter).map(({ start, end }) => ({
        label: `${translate("fiscal_year")} ${formatFiscalYear(start)}`,
        value: {
            start,
            end,
        },
    }));

    const withoutRangeOption = {
        label: translate(translationKeys.VDLANG_TIME_RANGE_SELECT_WITHOUT_TIME_RANGE),
        value: withoutRangeValue,
    };

    const totalRangeOption = {
        label: translate("total_time_range"),
        value: {
            start: moment.utc(`${MinYear}-01-01`), // use string as initializer, otherwise isSame() of momentjs will not work
            end: moment.utc(`${MaxYear - 1}-12-31`),
        },
    };

    const options: ITimeRangeOption[] = showOnlyFiscalYears
        ? fiscalYearOptions
        : [withoutRangeOption, ...fiscalYearOptions, totalRangeOption];

    let selectedOption = options.find((option) => isSameFiscalYear(value, option.value));
    if (selectedOption === undefined) {
        selectedOption = {
            label: translate("custom_time_range"),
            value: {
                start: value.start,
                end: value.end,
                customRange: true,
            },
        };

        options.unshift(selectedOption);
    }

    return (
        <Stack spacing={1}>
            <div>
                <Select
                    margin="none"
                    options={options}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    placeholder={translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={disabled}
                    aria-label={translate("time_range")}
                />
            </div>
            <Collapse
                in={!showOnlyFiscalYears && selectedOption !== totalRangeOption && selectedOption !== withoutRangeOption}
                // don't keep mounted when closed, to avoid Stack margin being applied on empty container element
                unmountOnExit
                mountOnEnter
            >
                <CalculationTimerangeSelect
                    granularity={MeasureCalculationGranularity.MONTH}
                    fiscalYearStart={financialMonth}
                    start={value.start}
                    end={value.end}
                    onStartChanged={handleStartDateChange}
                    onEndChanged={handleEndDateChange}
                    maxStart={value.end ?? undefined}
                    minEnd={value.start ?? undefined}
                    translate={translate}
                    disabled={disabled}
                />
            </Collapse>
        </Stack>
    );
};

export default TimeRangeSelect;
