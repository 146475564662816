import { Popover, styled, textFieldClasses } from "@mui/material";
import { EffectCategoryAttributeDto } from "api-shared";
import { TFunction } from "i18next";
import { isNumber, omit } from "lodash";
import { useCallback } from "react";
import FieldSelect from "../../../components/input/FieldSelect";
import SelectButton from "../../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../../components/input/select/components/SelectStaticMenu";
import FieldTreeInput from "../../../components/input/tree/FieldTreeInput";
import useMenu from "../../../hooks/useMenu";
import OptionsProvider, { OptionsProviderInjectedProps } from "../../OptionsProvider";
import TreeProvider from "../../TreeProvider";

const SelectContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(33), // align menu width with the width of the triggering SelectButton
    [`& .${textFieldClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

interface IScopeAttributeSelectProps {
    className?: string;
    field: EffectCategoryAttributeDto;
    scopeAttributes: Record<string, number[]>;
    updateScopeAttributes: (newAttributes: Record<string, number[]>) => void;
    translate: TFunction;
    disabled?: boolean;
}

type ScopeAttributeSelection = number | number[] | null;

const ScopeAttributeSelect = ({
    className,
    field,
    translate,
    scopeAttributes,
    updateScopeAttributes,
    disabled = false,
}: IScopeAttributeSelectProps) => {
    const { openMenu, menuProps } = useMenu();

    const accessor = field.title;
    const value = scopeAttributes[accessor];

    const handleChange = useCallback(
        (newValue: ScopeAttributeSelection) => {
            // remove old value(s)
            const otherAttributes = omit(scopeAttributes, field.title);

            if (newValue === null || (Array.isArray(newValue) && newValue.length === 0)) {
                // only remove the old values
                updateScopeAttributes(otherAttributes);
                return;
            }

            const changes: Record<string, number[]> = {};
            const list = Array.isArray(newValue) ? newValue : [newValue];

            const ids = list.filter(isNumber);

            if (ids.length > 0) {
                changes[field.title] = ids;
            }

            const newAttributes = {
                ...otherAttributes,
                ...changes,
            };

            updateScopeAttributes(newAttributes);
        },
        [updateScopeAttributes, scopeAttributes, field],
    );

    // FieldTreeInput has its own resting state & dialog, no need for a popover
    if (field.type === "tree") {
        return (
            <TreeProvider
                field={field}
                component={FieldTreeInput}
                componentProps={{
                    field,
                    translate,
                    updateValue: handleChange,
                    isMulti: true,
                    value,
                    label: translate(field.title),
                    dense: true,
                    narrowSelection: false,
                    fullWidth: true,
                    disabled,
                }}
            />
        );
    }

    const buttonProps = {
        fullWidth: true,
        disabled: disabled && (!value || value.length === 0),
    };

    return (
        <>
            <Popover {...menuProps}>
                <SelectContainer>
                    <OptionsProvider key={field.title} field={field} value={value}>
                        <FieldSelect
                            {...OptionsProviderInjectedProps}
                            isClearable
                            menuIsOpen={!disabled}
                            isMulti
                            size="small"
                            smallGutters
                            field={field}
                            value={value}
                            disabled={disabled}
                            updateValue={handleChange}
                            placeholder={`${translate("Search")}...`}
                            components={{
                                Menu: SelectStaticMenuContainer,
                                DropdownIndicator: undefined,
                            }}
                        />
                    </OptionsProvider>
                </SelectContainer>
            </Popover>
            <SelectButton label={translate(field.title)} count={value?.length} onClick={openMenu} {...buttonProps} />
        </>
    );
};

export default ScopeAttributeSelect;
