import { Popover, styled, textFieldClasses } from "@mui/material";
import { CurrencyDto } from "api-shared";
import { useTranslation } from "react-i18next";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import Select from "./Select";
import SelectButton from "./SelectButton";
import SelectStaticMenuContainer from "./components/SelectStaticMenu";
import { ISelectProps, Option } from "./types";

const SelectContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(33), // align menu width with the width of the triggering SelectButton
    [`& .${textFieldClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

interface ICurrencySelectProps extends Omit<ISelectProps<Option<number>, boolean>, "onChange" | "value" | "isMulti"> {
    currencies: CurrencyDto[];
    onChange: (newValue: number[] | number | null) => void;
    value: number[] | number | null;
    labelVariant?: "code" | "full";
    disabled?: boolean;
    className?: string;
}

const CurrencyFilter = ({ currencies, onChange, value, labelVariant = "full", disabled, className }: ICurrencySelectProps) => {
    const { t: translate } = useTranslation();
    const { openMenu, menuProps } = useMenu();

    const currencyOptions: Option<number>[] = currencies.map((currency) => ({
        label: labelVariant === "code" ? `${currency.isoCode}` : `${currency.isoCode} (${currency.name})`,
        primaryLabel: `${currency.isoCode} (${currency.name})`,
        value: currency.id,
    }));

    const selectedOptions = Array.isArray(value)
        ? currencyOptions.filter((o) => value.includes(o.value ?? 0))
        : currencyOptions.filter((o) => value != null && o.value === +value);

    const handleChange = (option: readonly Option<number>[] | Option<number> | null) => {
        if (option == null) {
            onChange(null);
            return;
        }

        if (Array.isArray(option)) {
            onChange(option.map((o) => o.value));
        } else {
            const single = option as Option<number>;
            onChange(single.value);
        }
    };

    return (
        <>
            <Popover {...menuProps}>
                <SelectContainer>
                    <Select
                        margin="none"
                        isMulti
                        menuIsOpen={!disabled}
                        size="small"
                        smallGutters
                        value={selectedOptions}
                        isDisabled={disabled}
                        onChange={handleChange}
                        options={currencyOptions}
                        placeholder={`${translate("Search")}...`}
                        isSearchable={true}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                    />
                </SelectContainer>
            </Popover>
            <SelectButton
                fullWidth
                label={translate(translationKeys.VDLANG_SCOPE_CURRENCY)}
                count={Array.isArray(value) ? value.length : 0}
                onClick={openMenu}
                className={className}
                disabled={disabled && selectedOptions.length === 0}
            />
        </>
    );
};

export default CurrencyFilter;
