import { Stack } from "@mui/material";
import { MeasureConfigDto, ScopeDto, formatDateForAPI } from "api-shared";
import { TFunction } from "i18next";
import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import CurrencyFilter from "../../../components/input/select/CurrencyFilter";
import { useCurrencies } from "../../../domain/currencies";
import { translationKeys } from "../../../translations/main-translations";
import ScopeAttributeSelect from "./ScopeAttributeSelect";
import TimeRangeSelect from "./TimeRangeSelect";
import OverlineLabel from "../../../components/OverlineLabel";

interface IScopeSelectProps {
    value: ScopeDto;
    onChange: (scope: ScopeDto) => void;
    label?: React.ReactNode;
    measureConfigIds: number | number[];
    measureConfigs: MeasureConfigDto[];
    yearsBefore?: number;
    yearsAfter?: number;
    financialMonth?: number;
    menuPortalTarget?: HTMLElement | null;
    translate: TFunction;
    disabled?: boolean;
    showOnlyFiscalYears?: boolean;
    skipTimeRange?: boolean;
    skipFields?: string[];
}

const ScopeSelect = ({
    value,
    onChange,
    label,
    measureConfigIds,
    measureConfigs,
    yearsBefore,
    yearsAfter,
    financialMonth,
    menuPortalTarget,
    translate,
    disabled,
    showOnlyFiscalYears,
    skipTimeRange,
    skipFields,
}: IScopeSelectProps) => {
    // resolve relevant effectcategoryattributes from active measureConfigs
    const visibleMeasureConfigs = measureConfigs.filter(({ id }) =>
        Array.isArray(measureConfigIds) ? measureConfigIds.includes(id) : id === measureConfigIds,
    );
    const visibleAttributes = visibleMeasureConfigs.flatMap((mc) => mc.effectCategoryAttributes);

    const fields = uniqBy(sortBy(visibleAttributes, "order"), "title").filter(({ title }) => {
        if (skipFields === undefined || skipFields.length === 0) {
            return true;
        }
        return !skipFields.includes(title);
    });
    // be careful with unique attributes from here, measureConfigId might be mixed between processes

    const scopeAttributes = value?.attributes ?? ({} as Record<string, number[]>);

    const currencies = useCurrencies();

    function onCurrencyChange(newValue: number[] | number | null) {
        if (Array.isArray(newValue)) {
            onChange({ ...value, currencyIds: newValue });
        } else {
            onChange({ ...value, currencyIds: newValue != null ? [newValue] : [] });
        }
    }

    return (
        <Stack spacing={2}>
            {!skipTimeRange && (
                <div>
                    {label ? <OverlineLabel>{label}</OverlineLabel> : null}
                    <TimeRangeSelect
                        value={{
                            start: value.startDate !== null ? moment.utc(value.startDate) : null,
                            end: value.endDate !== null ? moment.utc(value.endDate) : null,
                        }}
                        onChange={({ start, end }) =>
                            onChange({
                                ...value,
                                startDate: formatDateForAPI(start) ?? null,
                                endDate: formatDateForAPI(end) ?? null,
                            })
                        }
                        financialMonth={financialMonth}
                        yearsBefore={yearsBefore}
                        yearsAfter={yearsAfter}
                        menuPortalTarget={menuPortalTarget}
                        translate={translate}
                        disabled={disabled}
                        showOnlyFiscalYears={showOnlyFiscalYears}
                    />
                </div>
            )}

            {currencies.length > 1 && (
                <CurrencyFilter
                    currencies={currencies}
                    onChange={onCurrencyChange}
                    value={value.currencyIds ?? null}
                    label={translate(translationKeys.VDLANG_SCOPE_CURRENCY)}
                    disabled={disabled}
                />
            )}

            {fields.map((field) => (
                <ScopeAttributeSelect
                    key={field.title}
                    disabled={disabled}
                    field={field}
                    translate={translate}
                    scopeAttributes={scopeAttributes}
                    updateScopeAttributes={(attributes) => onChange({ ...value, attributes })}
                />
            ))}
        </Stack>
    );
};

export default ScopeSelect;
