import {
    AttributeTitle,
    AttributeType,
    buildEffectKey,
    CurrencyDto,
    DEFAULT_FILTER_ID,
    EffectFilterCurrencyField,
    EffectValueType,
    effortConverter,
    EXPORT_LOCALES,
    ExportLocale,
    FieldDefinition,
    FieldTypes,
    FilterDefinition,
    FilteredMeasureDto,
    formatDateForAPI,
    GlobalCalculationIdentifier,
    IFieldDefinition,
    MeasureFieldNames,
    mergeCamelized,
    nonNullable,
    ScopeDto,
    Sort,
} from "api-shared";
import moment from "moment";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClientFiscalYear } from "../../../domain/client";
import { useCurrencies } from "../../../domain/currencies";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useGroups } from "../../../domain/group";
import { useMeasuresCSV } from "../../../domain/measure/list";
import { useAllUsers } from "../../../domain/users";
import useCurrency, { CurrencyFormatter } from "../../../hooks/useCurrency";
import useFieldData from "../../../hooks/useFieldData";
import { useGetDynamicFieldLabel } from "../../../hooks/useGetDynamicFieldLabel";
import useRationalNumber from "../../../hooks/useRationalNumber.tsx";
import { reportError } from "../../../infrastructure/sentry";
import { trackEvent } from "../../../infrastructure/tracking";
import { getDefaultLanguage } from "../../../lib/bootstrap";
import { parse } from "../../../lib/csv-parse";
import { TranslatedOption } from "../../../lib/field-options";
import { Field, findField, isTranslateableConstant, mapConstantsToTranslations } from "../../../lib/fields";
import { formatUserFromId, removeLineBreaks } from "../../../lib/formatters";
import { getFullGroupPath } from "../../../lib/groups";
import { replaceImage } from "../../../lib/history";
import { replaceMentionUsers } from "../../../lib/mention";
import { RouteFor } from "../../../lib/routes.ts";
import { renderPath } from "../../../lib/tree.ts";
import { download } from "../../../lib/trigger-csv-download";
import { translationKeys } from "../../../translations/main-translations";
import useCurrencyContext from "../../CurrencyContext";
import CSVExportDialogContent from "./CSVExportDialogContent";

interface CSVSearchConfig {
    filter?: FilterDefinition;
    filterId: number | null;
    gridSort: Sort;
    gridOrderBy: string;
    measureConfigGridIds?: number[];
    scope: ScopeDto;
    gridColumns: string[];
}
interface CSVExportDialogProps {
    open: boolean;
    searchConfig: CSVSearchConfig;
    onClose: () => void;
}

type SplitMonthlyValueColumn = {
    label: string;
    month: number;
    year: number;
};

function getExportableColumns(columns: string[]): string[] {
    return columns.filter((column) => column !== MeasureFieldNames.ProcessPulse);
}

/**
 * Build map structure for fast access to field values by field name and id
 *
 * @param {Field[]} fields
 * @param {TranslatedOption[][]} data
 * @returns
 */
function buildFieldDataMap(fields: Field[], data: TranslatedOption[][]): Map<string, Map<number, string>> {
    return new Map(
        data
            .map((translatedOptions, index) => {
                const field = fields[index];

                if (field === undefined) {
                    return undefined;
                }
                // Build id -> name map for fast resolving of field values
                return [field.title, new Map(translatedOptions.map(({ id, name }) => [id, name]))] as const;
            })
            .filter(nonNullable),
    );
}

function CSVExportDialog({ open, searchConfig, onClose }: Readonly<CSVExportDialogProps>) {
    const [isUnwindEnabled, setIsUnwindEnabled] = React.useState(false);
    const [isBlankUnwindEnabled, setIsBlankUnwindEnabled] = React.useState(false);
    const [isLinkColumnEnabled, setIsLinkColumnEnabled] = React.useState(false);
    const [isPrintFormattingEnabled, setIsPrintFormattingEnabled] = React.useState(false);
    const [splitCurrencyFieldsIntoMonthlyValues, setSplitCurrencyFieldsIntoMonthlyValues] = React.useState(false);
    const [showValuesInEffectCategoryCurrency, setShowValuesInEffectCategoryCurrency] = React.useState(false);
    const [isExcelMetadataEnabled, setIsExcelMetadataEnabled] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<ExportLocale>(
        EXPORT_LOCALES.find((locale) => locale.startsWith(getDefaultLanguage())) ?? "en-gb",
    );

    const fiscalYearStart = useClientFiscalYear();
    const groups = useGroups();

    // Checks if a single full fiscal year is selected in the scope
    const isFiscalYearScope = useMemo(() => {
        if (searchConfig.scope.startDate === null && searchConfig.scope.endDate === null) {
            return false;
        }

        const startMoment = moment.utc(searchConfig.scope.startDate);
        const endMoment = moment.utc(searchConfig.scope.endDate);

        // Custom scope should not start before client fiscal year start to be within fiscal year scope
        if (startMoment.month() < fiscalYearStart) {
            return false;
        }
        const monthDiff = endMoment.diff(startMoment, "month");
        // Month scope should cover every month of the fiscal year
        return monthDiff === 11;
    }, [fiscalYearStart, searchConfig.scope.endDate, searchConfig.scope.startDate]);

    const getMonthQueryOptions = (calendarMonth: number) => {
        if (searchConfig.scope.startDate === null || searchConfig.scope.endDate === null) {
            // skip queries when there is no scope
            return { enabled: false };
        }

        if (!splitCurrencyFieldsIntoMonthlyValues) {
            // skip queries if no monthly values are needed
            return { enabled: false };
        }

        const startMoment = moment.utc(searchConfig.scope.startDate);
        const endMoment = moment.utc(searchConfig.scope.endDate);

        // If calendarMonth is smaller than fiscalYearStart, the effects for calendarMonth are in the next calendar year
        const year = calendarMonth < fiscalYearStart ? endMoment.year() : startMoment.year();

        const fiscalYearWithMonth = moment.utc({ year, month: calendarMonth, day: 1 });
        const fiscalMonthStart = fiscalYearWithMonth.clone().startOf("month");
        const fiscalMonthEnd = fiscalYearWithMonth.clone().endOf("month");

        return {
            enabled: fiscalYearWithMonth.isBetween(startMoment, endMoment, "month", "[]"),
            scope: {
                ...searchConfig.scope,
                startDate: formatDateForAPI(fiscalMonthStart),
                endDate: formatDateForAPI(fiscalMonthEnd),
            },
        };
    };

    const measuresCSVOptions = {
        filter: searchConfig.filter,
        filterId: searchConfig.filterId ?? DEFAULT_FILTER_ID,
        page: 0,
        pageSize: Number.MAX_SAFE_INTEGER,
        sort: searchConfig.gridSort,
        orderBy: searchConfig.gridOrderBy,
        measureConfigIds: searchConfig.measureConfigGridIds,
        scope: searchConfig.scope,
    };
    const measures = useMeasuresCSV(measuresCSVOptions);
    const januaryMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(0),
    });
    const februaryMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(1),
    });
    const marchMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(2),
    });
    const aprilMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(3),
    });
    const mayMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(4),
    });
    const juneMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(5),
    });
    const julyMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(6),
    });
    const augustMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(7),
    });
    const septemberMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(8),
    });
    const octoberMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(9),
    });
    const novemberMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(10),
    });
    const decemberMeasures = useMeasuresCSV({
        ...measuresCSVOptions,
        ...getMonthQueryOptions(11),
    });
    const monthlyMeasureData = [
        januaryMeasures.data,
        februaryMeasures.data,
        marchMeasures.data,
        aprilMeasures.data,
        mayMeasures.data,
        juneMeasures.data,
        julyMeasures.data,
        augustMeasures.data,
        septemberMeasures.data,
        octoberMeasures.data,
        novemberMeasures.data,
        decemberMeasures.data,
    ];
    const isFetching =
        measures.isFetching ||
        januaryMeasures.isFetching ||
        februaryMeasures.isFetching ||
        marchMeasures.isFetching ||
        aprilMeasures.isFetching ||
        mayMeasures.isFetching ||
        juneMeasures.isFetching ||
        julyMeasures.isFetching ||
        augustMeasures.isFetching ||
        septemberMeasures.isFetching ||
        octoberMeasures.isFetching ||
        novemberMeasures.isFetching ||
        decemberMeasures.isFetching ||
        groups.isFetching;

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const { t: translate } = useTranslation();
    const clientCurrencies = useCurrencies();

    const users = useAllUsers();
    const measureAttributes = useMeasureAttributes();

    const { currencyIsoCode, formatCurrency, formatCurrencyNoCode } = useCurrency({ language: selectedLanguage });
    const { formatRationalNumber } = useRationalNumber({ language: selectedLanguage });
    const currencyContext = useCurrencyContext();
    const { getDynamicColumnLabel } = useGetDynamicFieldLabel();

    const defaultExportableColumns = searchConfig.gridColumns.filter((column) => column !== MeasureFieldNames.ProcessPulse);

    const gridFieldDefinitions = fieldDefinitionsQuery.isSuccess
        ? defaultExportableColumns
              .map((column) => fieldDefinitionsQuery.data[column])
              .filter((field): field is IFieldDefinition => field != null)
        : [];

    const fields = useMemo(
        () =>
            fieldDefinitionsQuery.isSuccess
                ? getExportableColumns(searchConfig.gridColumns)
                      .map((column) => findField(measureAttributes, fieldDefinitionsQuery.data, column))
                      .filter(nonNullable)
                      // DANGER: field contains attribute type, NOT field type
                      .filter((field) => field.type !== AttributeType.Text && field.type !== AttributeType.Number)
                : [],
        [searchConfig, measureAttributes, fieldDefinitionsQuery.isSuccess, fieldDefinitionsQuery.data],
    );

    const fieldDataArray = useFieldData(fields, { fullTreePathValues: true });

    function resolveId(value: any, fieldDataMap: Map<string, Map<number, string>>, attributeName: string): string | null {
        if (Array.isArray(value)) {
            return value.map((v) => fieldDataMap.get(attributeName)?.get(v) ?? "").join(", ");
        }

        return fieldDataMap.get(attributeName)?.get(value) ?? null;
    }

    const fullGroupPath = (id: number): string => {
        if (groups.data === undefined) {
            return "-"; // Should not happen
        }

        const group = groups.data.find((g) => g.id === id);
        if (group === undefined) {
            return id.toString();
        }

        const path = getFullGroupPath(groups.data, group);
        return renderPath(path, mergeCamelized("name", selectedLanguage));
    };

    const resolveGroupTree = (value: any) => {
        return value.map((groupId: any) => fullGroupPath(groupId)).join(", ");
    };

    function resolveFieldValue(
        field: FieldDefinition,
        value: any,
        fieldDataMap: Map<string, Map<number, string>>,
        currencyFormatter: CurrencyFormatter,
    ): string | null {
        if (value == null) {
            return null;
        }

        if (isTranslateableConstant(field.name)) {
            const wrappedValue = Array.isArray(value) ? value : [value];
            const translationKeys = wrappedValue.map((v) => mapConstantsToTranslations(field.name, v));
            return translationKeys.map((key) => (key != null ? translate(key) : null)).join(", ");
        }

        if (field.name === MeasureFieldNames.Title) {
            return removeLineBreaks(value);
        }

        if (field.name === AttributeTitle.Description) {
            return removeLineBreaks(replaceImage(replaceMentionUsers(users, value, translate)));
        }

        if (field.type === FieldTypes.TimeEstimate) {
            return effortConverter(value);
        }
        if (field.name === MeasureFieldNames.GroupsWithAccess) {
            return resolveGroupTree(value);
        }
        if (field.name === MeasureFieldNames.LastModificationAt) {
            // Use ISO time stamp for the export and format it for compatibility with Excel
            return moment.utc(value).format("YYYY-MM-DD HH:mm:ss");
        }
        const attributeName = field.attributeName ?? field.name;
        switch (field.type) {
            case FieldTypes.User:
                return formatUserFromId(value, users, { translate });
            case FieldTypes.Single:
            case FieldTypes.Set:
            case FieldTypes.Users:
                return resolveId(value, fieldDataMap, attributeName);
            case FieldTypes.Currency:
                return currencyFormatter(value);
            case FieldTypes.Date:
                return moment.utc(value).format("YYYY-MM-DD");
            case FieldTypes.Double:
                return formatRationalNumber(Number(value));
            default:
                return value;
        }
    }

    function getCurrencyFormatter(currency: CurrencyDto | undefined) {
        let currencyFormatter: CurrencyFormatter = showValuesInEffectCategoryCurrency
            ? (value) => formatCurrency(value, currency)
            : formatCurrency;

        if (!isPrintFormattingEnabled) {
            currencyFormatter = formatCurrencyNoCode;
        }
        return currencyFormatter;
    }

    // Generates monthly values for a currency field for a range between two dates
    const generateMonthlyValueColumns = (
        startDate: string,
        endDate: string,
        fieldDefinition: IFieldDefinition,
        fields: Record<string, unknown>,
        measureId: number,
        currencyFormatter: CurrencyFormatter,
        effectCategoryId?: number,
    ) => {
        const monthlyCurrencyValueColumns: SplitMonthlyValueColumn[] = [];
        const endDateValue = moment.utc(endDate);
        const date = moment.utc(startDate);
        while (date <= endDateValue) {
            const year = date.year();
            const month = date.month();
            monthlyCurrencyValueColumns.push({
                year,
                month,
                label: `${translate(fieldDefinition.name)} - ${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}`,
            });
            date.add(1, "months");
        }
        monthlyCurrencyValueColumns.forEach((col: SplitMonthlyValueColumn) => {
            const effectFieldName = showValuesInEffectCategoryCurrency
                ? mergeCamelized("input", fieldDefinition.name)
                : fieldDefinition.name;
            let value = monthlyMeasureData[col.month]?.measures?.find(({ id }) => id === measureId)?.calculatedFields?.[effectFieldName];
            // When displaying monthly values in CSV export and display values for each effect, we need the values from effect category
            if (isUnwindEnabled && effectCategoryId !== undefined) {
                value = monthlyMeasureData[col.month]?.measures
                    ?.find(({ id }) => id === measureId)
                    ?.effectCategories.find(({ id }) => id === effectCategoryId)?.calculationFields?.[effectFieldName];
            }
            fields[col.label] = typeof value === "number" || typeof value === "string" ? currencyFormatter(value) : undefined;
        });
    };

    // can not use flatMap here because it does not work with dynamic type of buildEffectKey
    const effectFieldNames: string[] = Object.values(GlobalCalculationIdentifier)
        .map((identifier) => [
            buildEffectKey(identifier, EffectFilterCurrencyField.Effect, EffectValueType.Calculated),
            buildEffectKey(identifier, EffectFilterCurrencyField.Initial, EffectValueType.Calculated),
            buildEffectKey(identifier, EffectFilterCurrencyField.PriceHike, EffectValueType.Calculated),
            buildEffectKey(identifier, EffectFilterCurrencyField.Target, EffectValueType.Calculated),
        ])
        .flat();
    effectFieldNames.push(...Object.values(EffectFilterCurrencyField));

    const getExportData = () =>
        (measures.data?.measures ?? []).flatMap((measure: FilteredMeasureDto) => {
            const fieldDataMap = buildFieldDataMap(fields, fieldDataArray);
            const link = `${window.location.origin}${RouteFor.measure.forId(measure.id)}`;

            // Export one row for each effect category if unwind is enabled and measure has one or more effect categories
            if (isUnwindEnabled && measure.effectCategories.length > 0) {
                return measure.effectCategories.map((effectCategory, effectCategoryIndex) => {
                    const currency = clientCurrencies.find(({ id }) => id === effectCategory.currencyId);
                    const currencyFormatter = getCurrencyFormatter(currency);

                    const collectedFieldData = gridFieldDefinitions.reduce(
                        (effectCategoryFields, field) => {
                            const fieldName =
                                field.type === FieldTypes.Currency && showValuesInEffectCategoryCurrency
                                    ? mergeCamelized("input", field.name)
                                    : field.name;
                            const effectCategoryValue =
                                effectCategory.categoryFields[fieldName] ?? effectCategory.calculationFields[fieldName];
                            const measureValue =
                                (measure as any)[fieldName] ?? measure.fields[fieldName] ?? measure.calculatedFields[fieldName];

                            // Show the field value and prefer fields from effect category if they exist
                            // Fallback to measure value is disabled for effect category effect fields because if a effect category is not
                            // in scope the value of calculated effect fields is undefined and we must not use the measure value for those.
                            let value =
                                effectCategoryValue === undefined && !effectFieldNames.includes(field.name)
                                    ? measureValue
                                    : effectCategoryValue;

                            if (field.name == MeasureFieldNames.Currencies) {
                                value = showValuesInEffectCategoryCurrency && currency != null ? currency.id : currencyContext.id;
                            }

                            // If the blank unwind toggle is enabled, the current field is from a measure and it is not the first effect category
                            // Then the field should be empty because the field in the row above is the same
                            const hasMeasureValue = effectCategoryValue === undefined && measureValue !== undefined;
                            if (
                                isBlankUnwindEnabled &&
                                hasMeasureValue &&
                                effectCategoryIndex > 0 &&
                                field.name !== MeasureFieldNames.Currencies
                            ) {
                                effectCategoryFields[field.name] = null;
                            } else {
                                effectCategoryFields[field.name] = resolveFieldValue(field, value, fieldDataMap, currencyFormatter);
                            }

                            if (isFiscalYearScope && splitCurrencyFieldsIntoMonthlyValues && field.type === FieldTypes.Currency) {
                                generateMonthlyValueColumns(
                                    searchConfig.scope.startDate as string,
                                    searchConfig.scope.endDate as string,
                                    field,
                                    effectCategoryFields,
                                    measure.id,
                                    currencyFormatter,
                                    effectCategory.id,
                                );
                            }

                            return effectCategoryFields;
                        },
                        {} as Record<string, unknown>,
                    );

                    if (isLinkColumnEnabled) {
                        collectedFieldData[translate(translationKeys.VDLANG_EXPORT_CSV_LINK_COLUMN_TITLE)] = link;
                    }

                    return collectedFieldData;
                });
            }

            // Export one row for each measure
            const measureCurrencyFormatter = !isPrintFormattingEnabled ? formatCurrencyNoCode : formatCurrency;
            const measureExportData = gridFieldDefinitions.reduce(
                (measureFields, field) => {
                    const fieldName =
                        field.type === FieldTypes.Currency && showValuesInEffectCategoryCurrency
                            ? mergeCamelized("input", field.name)
                            : field.name;
                    const value = (measure as any)[fieldName] ?? measure.fields[fieldName] ?? measure.calculatedFields[fieldName];
                    measureFields[field.name] = resolveFieldValue(field, value, fieldDataMap, measureCurrencyFormatter);
                    if (isFiscalYearScope && splitCurrencyFieldsIntoMonthlyValues && field.type === FieldTypes.Currency) {
                        generateMonthlyValueColumns(
                            searchConfig.scope.startDate as string,
                            searchConfig.scope.endDate as string,
                            field,
                            measureFields,
                            measure.id,
                            measureCurrencyFormatter,
                        );
                    }
                    return measureFields;
                },
                {} as Record<string, unknown>,
            );

            if (isLinkColumnEnabled) {
                measureExportData[translate(translationKeys.VDLANG_EXPORT_CSV_LINK_COLUMN_TITLE)] = link;
            }

            return [measureExportData];
        });

    async function exportMeasures() {
        trackEvent({ category: "Export", action: "Csv", name: "Grid" });

        const exportData = getExportData();
        const [row] = exportData;
        const generatedColumns = Object.keys(row).filter((colName) => !defaultExportableColumns.includes(colName));

        // Append generated columns to default columns
        const columns = [...defaultExportableColumns, ...generatedColumns];

        const getColumnLabel = (column: string) => {
            // Translation of generated column is handled at the time of generation
            if (generatedColumns.includes(column)) {
                return column;
            }
            return MeasureFieldNames.Currencies === column && isUnwindEnabled
                ? translate(`${MeasureFieldNames.Currencies}_one`)
                : getDynamicColumnLabel(column, currencyIsoCode);
        };

        const fieldOptions = columns.map((column) => {
            return {
                label: getColumnLabel(column),
                value: column,
            };
        });

        const options = {
            fields: fieldOptions,
        };

        try {
            const csv = parse(exportData, options, isPrintFormattingEnabled, isExcelMetadataEnabled);
            download(csv);
        } catch (error) {
            reportError(error instanceof Error ? error : new Error("Error generating CSV export"), {
                extras: { cause: JSON.stringify(error) },
            });
        }
    }

    return (
        <CSVExportDialogContent
            open={open}
            toggleEffectCategories={isUnwindEnabled}
            toggleUnwindBlank={isBlankUnwindEnabled}
            printFormatting={isPrintFormattingEnabled}
            toggleLinkColumn={isLinkColumnEnabled}
            toggleAddExcelMetadata={isExcelMetadataEnabled}
            splitCurrencyFieldsIntoMonthlyValues={splitCurrencyFieldsIntoMonthlyValues}
            showValuesInEffectCategoryCurrency={showValuesInEffectCategoryCurrency}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            measures={measures.data}
            dataAvailable={!isFetching}
            isFiscalYearScope={isFiscalYearScope}
            translate={translate}
            export={exportMeasures}
            closeDialog={() => {
                onClose();
            }}
            handleChangeEffectCategories={(event: React.ChangeEvent<HTMLInputElement>) => setIsUnwindEnabled(event.target.checked)}
            handleChangeUnwindBlank={(event: React.ChangeEvent<HTMLInputElement>) => setIsBlankUnwindEnabled(event.target.checked)}
            handleChangeLinkColumn={(event: React.ChangeEvent<HTMLInputElement>) => setIsLinkColumnEnabled(event.target.checked)}
            handleChangePrintFormatting={(event: ChangeEvent<HTMLInputElement>) => setIsPrintFormattingEnabled(event.target.checked)}
            handleChangeEffectCategoryCurrency={(event: ChangeEvent<HTMLInputElement>) =>
                setShowValuesInEffectCategoryCurrency(event.target.checked)
            }
            handleChangeSplitCurrencyFieldsIntoMonthlyValues={(event: ChangeEvent<HTMLInputElement>) =>
                setSplitCurrencyFieldsIntoMonthlyValues(event.target.checked)
            }
            handleChangeAddExcelMetadata={(event: ChangeEvent<HTMLInputElement>) => setIsExcelMetadataEnabled(event.target.checked)}
        />
    );
}

export default React.memo(CSVExportDialog);
